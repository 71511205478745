import React from "react"

import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import { Section, SectionTitle, StyledContainer } from "../components/global"
import Footer from "../components/common/footer"

const Mnenja = () => (
  <Layout>
    <SEO title="Mnenja o knjigi" />
    <Navigation />
    <Section>
      <StyledContainer>
        <h4>
          Mnenje o knjigi katoliškega duhovnika, eksorcista koprske škofije
        </h4>
        <SectionDescription>
          <p>
            G. Boštjan Hari v svoji knjigi »Božja ljubezen odpušča in ozdravlja«
            približa sodobnemu človeku na njemu razumljiv način Božje usmiljenje
            in Njegovo delovanje v procesu odrešenja in ozdravljenja. Vsak
            človek slej ko prej pride do točke, ko ne vé, kako naprej, in ko
            začuti, da življenje ni samo to, kar se vidi ali na kar se je
            navadil. Vsak človek hrepeni po sreči, miru, zdravju in svobodi,
            hrepeni po odrešenju, hrepeni po Bogu. Mnogi se tega ne zavedajo.
            Pričujoča knjiga mu k temu lahko pomaga. Sam kot eksorcist sem se s
            pomočjo te knjige mnogo stvari na novo naučil ali obnovil. Ker pa
            mnogih stvari ne moreš vedno držati v svoji glavi, je ta knjiga kot
            priročnik, ki ga imaš vedno pri roki in ga lahko vzameš vedno v
            svoje roke. Vsi sestavki in teme so zelo dobro razloženi in so v
            skladu s predavanji in navodili, ki jih zagovarja tudi Mednarodno
            združenje eksorcistov (AIE). Vsem ljudem, ki iščejo pomoč,
            priporočim, da knjigo preberejo in se z njeno pomočjo pripravijo a
            življenjsko spoved. Lahko rečem, da bi jo morala imeti vsaka družina
            poleg Svetega pisma in molitvenika, ker je resnično duhovni
            priročnik. Poslanstvo vsakega kristjana je, da vsaj enega človeka
            pripelje k osebnemu odnosu z Bogom. Namen te knjige pa je, da bi
            človek spoznal na sebi razumljiv način Božjo ljubezen, Njegove
            zapovedi in pasti, ki jih postavlja hudobni duh na človekovo pot.
            Lahko se je izgovarjati na nevednost, »da nisem vedel«, toda vsak
            kristjan se je dolžan dati poučiti drugim, pa čeprav je to prek
            pisane besede. Zato lahko z veseljem rečem Bogu: Hvala za takšne
            duhovne pisatelje in posredovalce resnice.
          </p>
          <span>Janez Kavčič, eksorcist Koprske škofije</span>
        </SectionDescription>
      </StyledContainer>
    </Section>
    <Footer />
  </Layout>
)

const SectionDescription = styled.div`
  font-style: italic;
  span {
    float: right;
    font-weight: bold;
  }
`
export default Mnenja
